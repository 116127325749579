import Splide from '@splidejs/splide'
import Global from '../../assets/scripts/inc/global'
const { breakpoints } = Global

const Component = {
  init() {
    this.teamMembersSlider()
  },
  teamMembersSlider() {
    const teamMembersSlider = $('.splide.js-team-members-slider')
    if (teamMembersSlider.length) {
      teamMembersSlider.each(function () {
        new Splide(this, {
          type: 'loop',
          focus: 'center',
          start: 1,
          drag: 'free',
          gap: '2.375rem',
          arrows: true,
          pagination: false,
          autoWidth: true,
          perPage: 1,
          perMove: 1,
          omitEnd: true,
          autoplay: false,
          lazyLoad: false,
          breakpoints: {
            [breakpoints.lg]: {
              gap: '16px',
            },
            [breakpoints.md]: {
              gap: '8px',
              focus: 0,
              start: -1,
            },
          },
        }).mount()
      })
    }
  },
}

$(() => {
  Component.init()
})
